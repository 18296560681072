<template>
  <div class="immersive-container">
    <!--    <h2>Contributions</h2>-->
    <div class="card overflow-hidden position-relative">
      <div class="immersive-image"></div>
      <div class="card-body pt-1 pb-1">
        <div class="card-text text-justify">
          <div class="row mb-4 image-and-text">

            <div :lang="$i18n.locale" class="col-12 col-md-6 immersive-text pr-md-5 pr-3 py-3 pl-0">
              <div class="mb-2"
                   :class="{ 'styleEn': this.$root.$i18n.locale === 'en','styleRu': this.$root.$i18n.locale === 'ru','styleDe': this.$root.$i18n.locale === 'de' }">
                {{ $t('immersive_1') }}
              </div>
              <div
                  :class="{ 'styleEn': this.$root.$i18n.locale === 'en','styleRu': this.$root.$i18n.locale === 'ru','styleDe': this.$root.$i18n.locale === 'de' }">
                {{ $t('immersive_2') }}
                <span class="cursive"> {{ $t('immersive_3') }}</span>
                <span>{{ $t('immersive_4') }}</span>
                <span class="cursive">{{ $t('immersive_5') }}</span>
              </div>
            </div>
            <div class="col-12 col-md-6 mt-3 image-immersive immersive-img">
              <!--              <img src="../assets/img/immersive/01.jpg" class="img-fluid" alt="">-->
            </div>

            <!--            <div class="right-picture col-12 col-lg-6 col-md-12">-->
            <!--            </div>-->
            <!--            <div class="col-12 col-lg-12 col-md-12">-->
            <!--              <div class="row">-->
            <!--                <div :key="index" v-for="(item, index) in images" class="col-md-6 mb-3 col-sm-12" style="height: 400px">-->
            <!--                  <img :src="item.src" class="img-fluid" alt="" style="height: 100%;width: 100%">-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->
          </div>

          <div class="row contributions-row justify-content-around" id="links">
            <div class="col col-12 col-lg-6 d-flex mt-5 align-items-start" style="margin-bottom: 70px">
              <div class="card">
                <div class="card-body">
                  <div class="card-text">
                    <h4 class="text-center mb-3">{{ $t('contributions') }}</h4>
                    <div class="list-group" style="line-height: 30px">
                      <a style="font-size: 17px"
                         :class="{'text-dark': true, 'mb-1': true, 'text-decoration-none': award.link === ''}"
                         :target="award.link ? '_blank' : '_self'"
                         :href="award.link ? award.link : '#links'"
                         :key="index"
                         v-for="(award, index) in awards">
                        {{ award.name }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="col col-12 col-lg-6 mt-5">
              <carousel :loop="true" :perPage="1" :autoplay="true" :autoplayTimeout="5000" :navigationEnabled="true">
                <slide class="slide-image-container">
                  <img class="slide-img" src="../assets/immersive/slides/01.jpg" alt="">
                </slide>
                <slide
                    class="slide-image-container">
                  <img class="slide-img" src="../assets/immersive/slides/02.png" alt="">
                </slide>
                <slide
                    class="slide-image-container">
                  <img class="slide-img" src="../assets/immersive/slides/07.png" alt="">
                </slide>
                <slide
                    class="slide-image-container">
                  <img class="slide-img slide-img-max-width" src="../assets/immersive/slides/03.jpg" alt="">
                </slide>
                <slide
                    class="slide-image-container">
                  <img class="slide-img slide-img-max-width"
                       src="../assets/immersive/slides/04.jpg" alt="">
                </slide>
                <slide
                    class="slide-image-container">
                  <img style="max-height: 400px;" src="../assets/immersive/slides/05.jpg" alt="">
                </slide>
                <!--                <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">-->
                <!--                  <span class="carousel-control-next-icon" aria-hidden="true"></span>-->
                <!--                  <span class="sr-only">Next</span>-->
                <!--                </a>-->
              </carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Audios from '../components/Audios'
// import Plane from 'vue-loading-spinner/src/components/Plane'
// import {Carousel, Slide} from 'vue-carousel';

export default {
  name: "Immersive",
  components: {
    // Audios,
    // Plane,
    // Carousel, Slide
  },
  data() {
    return {
      imageLoading: true,
      percentage: 0,
      breakPoint: 992,
      audios: [
        {
          title: ['Elodie Bouchard', 'Anthony Harvey Torna', 'deh torna pargoletto mio'],
          image: 'img/immersive/_01.jpg',
          description: 'Amaizing description',
          audio: 'audio/_01.wav'
        },
      ],
      images: [
        // {src: 'img/immersive/01.jpg'},
        {src: 'img/immersive/02.jpg'},
        {src: 'img/immersive/03.jpg'},
        // {src: 'img/immersive/04.png'},

      ],
      awards: [
        {
          link: 'https://tonmeistertagung.com/en/2018/program/?id=13288',
          name: 'Tonmeistertagung 2018 ‘Mixing classical music in 3D is actually a challenge!’'
        },
        {
          link: 'https://en-de.neumann.com/neumann-provides-3d-playback-at-tonmeistertagung-in-cologne',
          name: '3D Playback for TMT 2018',
        },
        {
          link: '',
          name: 'Pro Sound Japan 2019',
        },
        {
          link: 'https://tonmeister.org/en/vdt-live/program/',
          name: 'VDT Live 2020 ‘Reverb as creative tool for classical music in 3D’'
        },
        {
          link: 'https://tonmeister.org/en/vdt-live/program/',
          name: 'VDT Live 2020 ‘Mixing classical music in Binaural’'
        },
        {
          link: '',
          name: 'Vdt Magazin 5 Heft 2020 Immersive Audio ‘Erfahrungen einer binauralen Mischungen auf Basis einen 3D Aufnahme’'
        },
        {
          link: '',
          name: 'AES Immersive Audio Academy 2 ‘Immersive in Classical Music’ Panel'
        }
      ]
    }
  },
  mounted() {
    this.$el.style.maxHeight = 'fit-content'
    const el = this.$el.querySelector('.VueCarousel-inner')
    if (el) {
      el.classList.add('align-items-center')
    }
  }
}
</script>

<style lang="scss">
.styleEn {
  line-height: 23px;
}

.styleRu {
  line-height: 21px;
}

.styleDe {
  line-height: 23px;
}

.card {
  border: none;
  //background: #CFD8DC;
  background: none;
}

.card-body {
  padding: 0;

  .image-and-text {
    justify-content: center;
    padding: 0 7rem;
    @media screen and (max-width: 1280px) {
      padding: 0 5rem;
    }
    @media screen and (max-width: 992px) {
      padding: 0 1rem;
    }
  }
}

.slide-image-container {
  height: 100%;
  display: flex;
  align-items: center;
  align-self: flex-end;
  text-align: center;
  justify-content: center;
  @media screen and (max-width: 600px) {
    align-self: center;
  }

  .slide-img {
    max-height: 400px;
    @media screen and (max-width: 600px) {
      width: 100%;
      max-height: none;
    }
  }
  .slide-img-max-width {
    max-width: 99%;
    align-self: end;
    @media screen and (max-width: 600px) {
      max-width: none;
    }
  }
}

.immersive-img {
  background: url("../assets/img/immersive/01.jpg");
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 420px;
  @media screen and (max-width: 768px) {
    min-height: 480px;
  }
}

.immersive-container {
  padding-left: 1rem;
  padding-right: 1rem;

  .image-immersive {
    display: grid;
    justify-items: center;
    align-items: flex-end;
  }

  .contributions-row {
    background: #ffffff;
    padding: 1rem 7rem;
    @media screen and (max-width: 1280px) {
      padding: 1rem 5rem;
    }
    @media screen and (max-width: 992px) {
      padding: 2rem;
    }
  }

  .immersive-image {
    position: absolute;
    top: 0;
    height: calc(100vh - 151px);
    width: 100%;
    //background-image: url("../assets/immersive-pick-new.jpg");
    //background-size: cover;
  }


  .immersive-text {
    //background-color: rgba(255, 255, 255, 0.7);
    border-radius: 0.25rem;
    hyphens: auto;
    word-break: break-word;
    //height: calc(100vh - (151px + 0.50rem));
    .cursive {
      font-family: raleway-medium-bold-cursive;
      cursor: text;
    }
  }

  // .right-picture {
  //   background-image: url("../assets/immersive-pick-new.jpg");
  //   background-size: cover;
  //   background-position: right center;
  //   background-repeat: no-repeat;
  //   background-color: rgb(140, 127, 107, 0.7);
  //   width: 100%;
  //   height: calc(100vh - (151px + 0.25rem));
  // }

  .immersive-text-with-picture {
    background-size: cover;
    background-position: center;
    position: relative;

    .immersive-text {
      text-align: justify;
      width: 50%;
      height: calc(100vh - 151px);
      z-index: 1;
      margin-left: auto;
      padding: 30px;

      @media screen and (max-width: 992px) {
        width: 100%;
        height: auto;
        color: white;
        padding: 15px;
        background-color: rgba(0, 0, 0, 0.3);
      }
    }

    .ksu-immersive-picture {
      display: flex;
      width: 50%;
      height: calc(100vh - 151px);
      /* background-image: url("../assets/ksu-back.jpg"); */
      background-size: cover;
      background-position: 47%;
      position: absolute;

      @media screen and (max-width: 992px) {
        width: 100%;
      }

      .image-hidden {
        display: none;
      }

    }

    .list-group-item {
      background-color: rgba(0, 0, 0, 0.4);
      color: #f8f9fa;

      &:hover {
        background-color: rgba(255, 255, 255, 0.7);
        cursor: pointer;
        color: black;
      }
    }

    @media screen and (max-width: 992px) {
      display: flex;
      padding: 15px;
    }
  }

  .plyr {
    height: auto;
  }
}

@media (max-width: 900px) {
  .immersive-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.VueCarousel-dot-container {
  margin-top: 0 !important;
}
</style>
